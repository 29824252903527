<template>
  <div class="row" style="height:770px;">
    <div class="col-md-12">
      <div class="col-12">
        <card style="height:752px">
          <survey-creator @update:template="updateTemplate"></survey-creator>
        </card>       
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import { Validator  } from 'vee-validate';
import SurveyCreator from 'src/components/SurveyCreator';


export default {
  components: {
    SurveyCreator
  },
	computed: {
		surveyState() {
			let self = this;
			return self.$store.state.survey.template;
		}
  },
  watch: {
		surveyState(newValue) {
      let self = this;
      self.model.template = self.$store.state.survey.template;
      //self.model.inUse = self.$store.state.survey.inUse;
      //self.model.isComplete = self.$store.state.survey.isComplete;

		}
	},
  data() {
    return {
      model: {
        template: '{}',
        //inUse: '',
        //isComplete: ''
      },
      modelValidations: {
        template: {
          //json: true,
          //required: true,
          //surveyTemplate: true
        }
      }
    };
  },

  methods: {
    updateTemplate(value){
      let self = this;
      self.model.template = value;
    },
    getError(fieldName) {
      let self = this;
      return self.errors.first(fieldName);
    },
    validate() {
      let self = this;

      if ( self.$store.state.survey.template === self.model.template && self.$store.state.survey.inUse != "True")
      {
        self.notifyVue ('top', 'right', 'info');
      }


      self.$store.state.survey.template = self.model.template;
      self.$store.commit('updateSurvey', self.$store.state.survey);
      return self.$validator.validateAll().then(isValid => {
        self.$emit("on-validated", self.survey, isValid);
        return isValid;
      });
    },
    notifyVue (verticalAlign, horizontalAlign, messageType) {
        this.$notify(
          {
            component: {
              template: `<span>No Changes Detected</br>Please save survey design to make changes</span>`
            },
            icon: 'fa fa-info',
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: messageType
          })
      }
  }
};
</script>


