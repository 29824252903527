<template>
  <div class="row">
    <div class="col-md-12">
      <div class="col-12">
        <card v-if="loaded">
          <div>
            <div class="card-title" style="font-size:18px; margin-bottom:20px">
            {{ model.controlFramework }} : {{ model.controlFrameworkCategory }}
            </div>
            <fg-input label="Description">
              <textarea
                name="description"
                v-model="model.description"
                class="form-control"
                style="height: 80px"
              ></textarea>
            </fg-input>
            <div>
              <fg-input label="Tags">
                <el-tag
                  :key="tag"
                  v-for="tag in tags.dynamicTags"
                  size="Default"
                  type="info"
                  effect="light"
                  :closable="true"
                  :close-transition="false"
                  @close="handleClose(tag)"
                  >{{ tag }}</el-tag
                >

                <input
                  type="text"
                  placeholder="New Tag"
                  class="form-control input-new-tag"
                  v-model="tags.inputValue"
                  ref="saveTagInput"
                  size="mini"
                  @keyup.enter="handleInputConfirm"
                  @blur="handleInputConfirm"
                />
              </fg-input>
            </div>

            <checkbox v-model="checkboxValue">Active</checkbox>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox } from "src/components/index";
import { Select, Option, Tag } from "element-ui";
import { mapMutations } from "vuex";

export default {
  components: {
    Checkbox,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tag.name]: Tag,
  },
  data() {
    return {
      checkboxValue: true,
      model: {
        id: 0,
        description: "",
        keywords: [],
        isActive: true,
        template: "{}",
        controlFrameworkId: "",
        controlFrameworkCategoryId: "",
        controlFramework: "",
        controlFrameworkCategory: "",
        lastModifiedById: 0,
        inUse: null,
        isComplete: null,
      },
      tags: {
        dynamicTags: [],
        inputVisible: false,
        inputValue: "",
      },
      loaded: true
    };
  },
  created() {
    let self = this;
    self.get(self.$route.params.id);
    self.getAllActiveControlFrameworks();
    self.$store.commit("updateSurvey", self.model);
  },
  methods: {
    validate() {
      let self = this;
      self.model.isActive = self.checkboxValue.toString();
      self.model.keywords = self.tags.dynamicTags;
      self.$store.commit("updateSurvey", self.model);
      return self.$validator.validateAll().then((isValid) => {
        self.$emit("on-validated", self.survey, isValid);
        return isValid;
      });
    },
    getAllActiveControlFrameworks() {
      let self = this;
      self.$store.state.services.controlFrameworkService
        .getAll({
          params: {
            ActiveControlFrameworks: true,
          },
        })
        .then((r) => {
          self.controlFrameworks = r.data;
        })
        .catch((err) => console.log(err));
    },
    getAllActiveControlFrameworkCategories(value) {
      let self = this;
      self.$store.state.services.controlFrameworkCategoryService
        .getAll({
          params: {
            ActiveControlFrameworkCategories: true,
            ControlFrameworkId: value,
          },
        })
        .then((r) => {
          self.controlFrameworkCategories = r.data;
        })
        .catch((err) => console.log(err));
    },
    get(id) {
      let self = this;
      if (id == undefined) {
        self.$store.state.services.controlFrameworkCategoryService
          .get(self.$route.params.controlFrameworkCategoryId)
          .then((r) => {
            self.model.controlFrameworkCategory = r.data.name;

            self.model.controlFramework = r.data.controlFramework;
            self.model.controlFrameworkId = parseInt(
              self.$route.params.controlFrameworkId
            );
            self.model.controlFrameworkCategoryId = parseInt(
              self.$route.params.controlFrameworkCategoryId
            );
            self.updateAdd(true)
            self.loaded = true;
          });
        return;
      }
      self.$store.state.services.surveyService
        .get(id)
        .then((r) => {
          self.model.id = r.data.id;
          self.model.controlFrameworkCategory = r.data.controlFrameworkCategory;
          self.model.controlFramework = r.data.controlFramework;
          self.model.description = r.data.description;
          self.model.keywords = r.data.keywords.trim().split(",");
          self.tags.dynamicTags = self.model.keywords;
          self.model.template = r.data.template;
          self.model.inUse = r.data.inUse;
          self.model.isComplete = r.data.isComplete;
          self.model.controlFrameworkCategoryId =
            r.data.controlFrameworkCategoryId;
          self.model.controlFrameworkId = r.data.controlFrameworkId;
          self.model.lastModifiedById = r.data.lastModifiedById;
          var myValue = r.data.isActive == "True";
          self.model.isActive = myValue;
          self.checkboxValue = myValue;
          self.getAllActiveControlFrameworkCategories(
            r.data.controlFrameworkId
          );
          self.updateInUse(self.model.inUse);
          self.loaded = true;
         
        })
        .catch((err) => console.log(err));
    },
    handleClose(tag) {
      let self = this;
      self.tags.dynamicTags.splice(self.tags.dynamicTags.indexOf(tag), 1);
    },
    handleInputConfirm() {
      let self = this;
      let inputValue = self.tags.inputValue.replace(/ +/g, " ");
      let trimedDynamicTags = self.tags.dynamicTags.map((tags) => tags.trim());
      if (inputValue && !trimedDynamicTags.includes(inputValue.trim())) {
        self.tags.dynamicTags.push(inputValue);
      }
      self.tags.inputVisible = false;
      self.tags.inputValue = "";
    },
    updateInUse(value) {
      let self = this;
      self.$emit("update:inuse", value);
    },
    updateAdd(value) {
      let self = this;
      self.$emit("update:add", value)
    }
  },
};
</script>
<style>
.tagHeader {
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
</style>
