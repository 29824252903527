<template>
  <div id="surveyCreatorContainer"></div>
</template>

<script>
import * as SurveyCreator from "survey-creator";
import "survey-creator/survey-creator.css";
import * as SurveyKo from "survey-knockout";
import * as widgets from "surveyjs-widgets";
import { init as customWidget } from "../components/customwidget";

import { mapMutations } from "vuex";

widgets.select2(SurveyKo);
widgets.inputmask(SurveyKo);
widgets.select2tagbox(SurveyKo);
//widgets.ckeditor(SurveyKo);
widgets.autocomplete(SurveyKo);

SurveyKo.Serializer.addProperty("question", "tag:number");
//SurveyKo.Serializer.addProperty("question", "weight:number");
SurveyKo
    .Serializer
    .addProperty("question", {
        name: "!weight:number",
        default: 0,
        category: "general"
    });

/*var CkEditor_ModalEditor = {
  afterRender: function (modalEditor, htmlElement) {
    var editor = window["CKEDITOR"].replace(htmlElement);
    editor.on("change", function () {
      modalEditor.editingValue = editor.getData();
    });
    editor.setData(modalEditor.editingValue);
  },
  destroy: function (modalEditor, htmlElement) {
    var instance = window["CKEDITOR"].instances[htmlElement.id];
    if (instance) {
      instance.removeAllListeners();
      window["CKEDITOR"].remove(instance);
    }
  },
};*/

var mainColor = "#087ea6";
var mainHoverColor = "#2a8eb0";
var textColor = "#4a4a4a";
var headerColor = "#7ff07f";
var headerBackgroundColor = "#4a4a4a";
var bodyContainerBackgroundColor = "#f8f8f8";

var defaultThemeColorsEditor =
  SurveyCreator.StylesManager.ThemeColors["default"];
defaultThemeColorsEditor["$primary-color"] = mainColor;
defaultThemeColorsEditor["$secondary-color"] = mainColor;
defaultThemeColorsEditor["$primary-hover-color"] = mainHoverColor;
defaultThemeColorsEditor["$primary-text-color"] = textColor;
defaultThemeColorsEditor["$selection-border-color"] = mainColor;

SurveyCreator.StylesManager.applyTheme("bootstrap");

SurveyCreator.SurveyPropertyModalEditor.registerCustomWidget(
  "html",
  //CkEditor_ModalEditor
);
export default {
  name: "survey-creator",
  computed: {
    surveyState() {
      let self = this;
      return self.$store.state.survey.template;
    },
  },
  watch: {
    surveyState(newValue) {
      let self = this;
      self.model.template = self.$store.state.survey.template;
      self.model.inUse = self.$store.state.survey.inUse;
      self.model.isComplete = self.$store.state.survey.isComplete;
      self.surveyCreator.text = self.$store.state.survey.template;

      if (self.$store.state.survey.inUse === "True") {
        //alert (self.$store.state.survey.inUse)
        self.surveyCreator.readOnly = true;
      }
    },
  },

  mounted() {
    let self = this;
    let options = {
      questionTypes: [
        "text",
        "checkbox",
        "radiogroup",
        "dropdown",
        "comment",
        "boolean",
        "html",
        "matrix",
        "matrixdropdown",
        "matrixdynamic",
        "panel",
        "paneldynamic",
      ],
      designerHeight: "700px",
    };
    this.surveyCreator = new SurveyCreator.SurveyCreator(
      "surveyCreatorContainer",
      options
    );

    //self.surveyCreator.text = self.$store.state.survey.template;

    this.surveyCreator.showToolbox = "right";
    this.surveyCreator.showPropertyGrid = "right";
    this.surveyCreator.rightContainerActiveItem("toolbox");

    this.surveyCreator.placeholderHtml =
      '<div style="position: absolute; top: 35%; left: 50%; transform: translate(-50%, -50%); text-align: center;">' +
      '<div class="svd-empty-message" style="font-size:13px; max-width: 250px;">' +
      "Drag and drop from toolbox to start creating your Design Effectiveness survey" +
      "</div>" +
      '<img src="static/img/drag-image.svg" style="width:150px; height:150px;" />' +
      "</div>";

    this.surveyCreator.saveSurveyFunc = function () {

      if (self.$store.state.survey.inUse === "True") {
        self.notifyVueInUse("top", "right", "warning");
      }
      else{
        self.updateTemplate(this.text);
        self.notifyVue("top", "right", "info");
      }
    };
  },

  data() {
    return {
      model: {
        template: "{}",
        inUse: "",
        isComplete: "",
      },
    };
  },

  methods: {
    updateTemplate(value) {
      let self = this;
      self.$emit("update:template", value);
    },
    notifyVue(verticalAlign, horizontalAlign, messageType) {
      this.$notify({
        component: {
          template: `<span>Survey creation saved temporarily</br>Please proceed to next steps to save or complete design</span>`,
        },
        icon: "fa fa-info",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: messageType,
      });
    },
    notifyVueInUse(verticalAlign, horizontalAlign, messageType) {
      this.$notify({
        component: {
          template: `<span></br>Save failed - Already in use</br>&nbsp;</span>`,
        },
        icon: "fa fa-exclamation-triangle",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: messageType,
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.svd-survey-placeholder-root .svd-survey-placeholder {
  position: relative;
  width: 100%;
  height: 280px;
}
/* licence hidden to pay for later */
.svd_commercial_container {
  display: none;
}
</style>