<template>
  <div class="row">
    <div class="col-md-12">
      <div class="col-12">
        <card>
          <survey :survey="survey"></survey>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import * as SurveyVue from "survey-vue";
import { mapMutations } from "vuex";
import "bootstrap/dist/css/bootstrap.css";

var Survey = SurveyVue.Survey;

Survey.cssType = "bootstrap";

SurveyVue.Serializer.addProperty("question", "tag:number");
SurveyVue.Serializer.addProperty("question", "weight:number");

export default {
  computed: {
    surveyState() {
      let self = this;
      return self.$store.state.survey.template;
    }
  },
  watch: {
    surveyState(newValue) {
      let self = this;
      try {
        self.survey = new SurveyVue.Model(
          JSON.parse(self.$store.state.survey.template)
        );
      } catch (error) {
        self.survey = new SurveyVue.Model(JSON.parse("{}"));
      }
    }
  },
  data() {
    return {
      survey: new SurveyVue.Model(JSON.parse("{}"))
    };
  }
};
</script>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.sv_q_m_cell_text {
  font-weight: normal !important;
  font-size: 13px !important;
  background-color: white !important;
}
.sv_q_m_cell_selected {
  background-color: #d5f5df !important;
}

.sv_q_matrix td {
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border: 3px solid black;
  font-size: 13px;
  widows: 16.66%;
  background-color: #f0f0f0;
}
.sv_q_matrix {
  table-layout: fixed;
  width: 100%;
}
.sv_prev_btn {
  border: 1px solid #087ea6;
  color: #ffffff;
  background-color: #087ea6;
  min-width: 140px;
  margin-left: 10px;
}
.sv_prev_btn:hover {
  border: 1px solid #087ea6;
  color: #ffffff;
  background-color: #087ea6;
}
.sv_next_btn:hover {
  border: 1px solid #087ea6;
  color: #ffffff;
  background-color: #087ea6;
}
.sv_next_btn {
  border: 1px solid #087ea6;
  color: #ffffff;
  background-color: #087ea6;
  min-width: 140px;
  margin-left: 10px;
}
.sv_complete_btn {
  border: 1px solid #7ea700;
  color: #ffffff;
  background-color: #7ea700;
  min-width: 140px;
  margin-left: 10px;
}
.sv_complete_btn:hover {
  border: 1px solid #7ea700;
  color: #ffffff;
  background-color: #7ea700;
}

.alert.alert-danger,
.vue-notifyjs.alert.alert-danger {
  background-color: #f7f7f8;
  color: red;
}
h3 {
  font-size: 18px !important;
}
h5 {
  font-size: 14px !important;
}
.sv-q-col-1 {
  font-size: 14px !important;
}
.card-footer {
  text-align: right !important;
}
.sv_main.sv_main .progress {
  width: 100%;
  height: 20px;
}
.progress-bar {
  background-color: #7ea700;
  text-align: left;
  padding-left: 15px;
}
</style>