<template>
<div class="container-fluid">
    <breadcrumb>
    <img  src="static/img/survey.svg" class="breadcrumb-image" />
      <breadcrumb-item>
        <router-link to="/controlframeworks">Control Frameworks</router-link>
      </breadcrumb-item>

      <breadcrumb-item v-if="add">
        <router-link :to="{ path: '../../../edit' }"
          >Control Framework Categories</router-link
        >
      </breadcrumb-item>
      <breadcrumb-item v-if="add == false">
        <router-link :to="{ path: '../../../../edit' }"
          >Control Framework Categories</router-link
        >
      </breadcrumb-item>

      <breadcrumb-item v-if="add">
        <router-link :to="{ path: '../surveys' }">Surveys</router-link>
      </breadcrumb-item>
      <breadcrumb-item v-if="add == false">
        <router-link :to="{ path: '../../surveys' }">Surveys</router-link>
      </breadcrumb-item>

      <breadcrumb-item v-if="add" active> Add Survey </breadcrumb-item>
      <breadcrumb-item v-if="add == false" active>
        Edit Survey
      </breadcrumb-item>
    </breadcrumb>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-12">
          <div class="card card-wizard" id="wizardCard">
            <form-wizard
              shape="circle"
              @on-complete="wizardComplete"
              title
              subtitle
              error-color="#FB404B"
              color="#087EA6"
            >
              <tab-content
                title="Survey Detail"
                class="col-12"
                :before-change="() => validateStep('descriptionStep')"
                icon="fa fa-info"
              >
                <description-step ref="descriptionStep" @on-validated="onStepValidated" @update:add="updateAdd" @update:inuse="updateInUse"></description-step>
              </tab-content>

              <tab-content
                title="Survey Creation"
                class="col-12"
                :before-change="() => validateStep('creationStep')"
                icon="fa fa-clipboard-list"
              >
                <creation-step ref="creationStep" @on-validated="onStepValidated"></creation-step>
              </tab-content>

              <tab-content
                title="Survey Review"
                class="col-12"
                icon="fa fa-thumbs-up"
              >
                <review-step ref="reviewStep"></review-step>
              </tab-content>

              <tab-content title="Finish" class="col-12" icon="fa fa-flag-checkered">
                <card style="height:410px;">
                <div style="padding-top:130px;">
                  <template v-if="model.inUse != 'True'">
                  <h4 class="text-center text-space">Click Finish to Complete Survey Setup </br> </br> or Save to Complete Later</h4>
                  </template>
                  <template v-if="model.inUse == 'True'">
                  <h4 class="text-center text-space">Survey is already in use </br> </br> Changes cannot be made</h4>
                  </template>
                </div>
                </card>
              </tab-content>
              <button slot="prev" style="height: 40px; margin-right: 5px" class="btn btn-fill btn-wd btn-back btn-info">
                <span class="btn-label">
                  <i class="fa fa-arrow-circle-left"></i>
                </span>
                Back</button>
               <template slot="custom-buttons-right" v-if="model.inUse != 'True'">
                  <button
                    type="button"
                    style="height: 40px; margin-right: 5px"
                    class="btn btn-fill btn-wd btn-next btn-danger"
                    @click="close"
                  >
                <span class="btn-label">
                  <i class="fa fa-times"></i>
                </span>Cancel
              </button>
                </template>
              <button slot="next" style="height: 40px" class="btn btn-fill btn-wd btn-next btn-info">
                Next
                <span class="btn-label">
                  <i class="fa fa-arrow-circle-right"></i>
                </span></button>
                <template slot="custom-buttons-right" v-if="model.inUse == 'True'">
                  <button
                    type="button"
                    style="height: 40px; margin-right: 5px"
                    class="btn btn-fill btn-wd btn-next btn-danger"
                    @click="close"
                  >
                <span class="btn-label">
                  <i class="fa fa-times"></i>
                </span>Close
              </button>
                </template>
                <template v-if="model.inUse != 'True'">
              <button slot="finish" @click="saveSurvey('temp')" class="btn btn-fill btn-wd btn-next btn-info">
                <span class="btn-label">
                  <i class="fa fa-save"></i>
                </span>
                Save
              </button>
              </template>
              <button slot="finish" @click="saveSurvey('finish')" class="btn btn-fill btn-wd btn-next btn-info">
                <span class="btn-label">
                  <i class="fa fa-flag-checkered"></i>
                </span>
                Finish
              </button>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import DescriptionStep from "./Wizard/DescriptionStep.vue";
import CreationStep from "./Wizard/CreationStep.vue";
import ReviewStep from "./Wizard/ReviewStep.vue";
import swal from "sweetalert2";
import { mapState } from "vuex";
import { Breadcrumb, BreadcrumbItem } from "src/components/index";

export default {
  data() {
    return {
      wizardModel: {},
      model: {
        //id: 0,
        survey: {},
        inUse: "",
      },
      add: false,
    };
  },
  components: {
    FormWizard,
    TabContent,
    DescriptionStep,
    CreationStep,
    ReviewStep,
    Breadcrumb,
    BreadcrumbItem
  },

  methods: {
    saveTemp() {},
    validateStep(ref) {
      let self = this;
      return self.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      let self = this;
      self.wizardModel = { ...self.wizardModel, ...model };
    },
    wizardComplete() {
      //let self = this;
      //self.$router.push("/surveys");
    },
    saveSurvey(value) {
      let self = this;
      if (self.model.inUse != "True") {
        var survey = self.$store.state.survey;
        var surveyId = self.$route.params.id;
        survey.keywords = survey.keywords.join(", ");
        if (value == "finish") {
          survey.isComplete = "True";
        }
        if (surveyId == undefined) {
          self.$store.state.services.surveyService.add(survey).then((r) => {
            if (value == "finish") {
              self.notifyVueFinish("top", "right", "success");
            } else {
              self.notifyVueSave("top", "right", "warning");
            }
          });
        } else {
          self.$store.state.services.surveyService
            .update(survey)
            .then((r) => {
              if (value == "finish") {
                self.notifyVueFinish("top", "right", "success");
              } else {
                self.notifyVueSave("top", "right", "warning");
              }
            });
        }
      }
      self.close();
      //self.$router.push("/surveys").catch((err) => {});
    },
    close() {
      let self = this;
      self.$router.push(
        "/controlFrameworks/" +
          self.$route.params.controlFrameworkId +
          "/controlframeworkcategories/" +
          self.$route.params.controlFrameworkCategoryId +
          "/surveys"
      );
    },
    notifyVueSave(verticalAlign, horizontalAlign, messageType) {
      this.$notify({
        component: {
          template: `<span>Survey creation saved</br>but not completed</span>`,
        },
        icon: "fa fa-info",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: messageType,
      });
    },
    notifyVueFinish(verticalAlign, horizontalAlign, messageType) {
      this.$notify({
        component: {
          template: `<span>Survey creation saved</br>and completed</span>`,
        },
        icon: "fa fa-check",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: messageType,
      });
    },
    notifyVueInUse(verticalAlign, horizontalAlign, messageType) {
      this.$notify({
        component: {
          template: `<span>Survey in use</br>Changes will not be saved</span>`,
        },
        icon: "fa fa-info",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: messageType,
      });
    },
    updateInUse(value) {
      let self = this;
      self.model.inUse = value;
      if (value === "True") {
        self.notifyVueInUse("top", "right", "info");
      }
    },
    updateAdd(value) {
      let self = this;
      self.add = value;
    }
  },
};
</script>
<style lang="scss">
.vue-form-wizard .wizard-icon-circle.tab_shape {
  background-color: #9a9a9a !important;
  color: white;
}
.vue-form-wizard .wizard-tab-content {
  display: flex; // to avoid horizontal scroll when animating
  .wizard-tab-container {
    display: block;
    animation: fadeIn 0.5s;
  }
}
.swal2-popup .swal2-styled.swal2-confirm {
  border: 1px solid #087ea6;
  border-radius: 0.25em;
  background: initial;
  background-color: #087ea6;
  color: #fff;
  font-size: 1.0625em;
}
</style>
